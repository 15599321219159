import React, { useState } from "react";
import { Link } from "gatsby";
import axios from "axios";
import Heading from "../../../common/components/Heading";
import ReCAPTCHA from "react-google-recaptcha";

import FormArea, {
  Container,
  CardFooter,
  CardTop,
  Col,
  PriceCard,
  PricingAmount,
  Row,
  Form,
  TopHeading,
} from "./register.style";

const RegistrationForm = () => {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });

  const [reCaptchaState, setReCaptchaState] = useState(false);

  const onChange = (value) => {
    console.log(value);
    setReCaptchaState(true);
  };

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      form.reset();
    }
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8",
      data: new FormData(form),
    })
      .then((r) => {
        handleServerResponse(true, "Thanks!", form);
      })
      .catch((r) => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };
  return (
    <>
      <FormArea>
        <Container>
          <TopHeading>
            {!true ? (
              <ReCAPTCHA
                className="container-recaptcha"
                sitekey="6LfoB9olAAAAAC2urCoCL8NOeiF0UuamGH62lWAt"
                onChange={onChange}
              />
            ) : (
              <>
                <Form>
                  <form
                    action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
                    method="POST"
                  >
                    <input type="hidden" name="oid" value="00D2w00000GlCo7" />
                    <input
                      type="hidden"
                      name="retURL"
                      value="https://www.secd3v.com.au/register/"
                    />
                    <Row>
                      <div className="form-group">
                        <input
                          type="text"
                          name="first_name"
                          className="form-control"
                          id="first_name"
                          placeholder="Enter your first name"
                          required="required"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          name="last_name"
                          className="form-control"
                          id="last_name"
                          placeholder="Enter your last name"
                          required="required"
                        />
                      </div>
                    </Row>

                    <Row>
                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          id="email"
                          aria-describedby="emailHelp"
                          placeholder="Enter email"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          name="city"
                          className="form-control"
                          id="city"
                          placeholder="City"
                          required="required"
                        />
                      </div>
                    </Row>

                    <Row>
                      <div className="form-group">
                        <input
                          type="text"
                          name="company"
                          className="form-control"
                          id="company"
                          placeholder="Company Name"
                          required="required"
                        />
                      </div>
                    </Row>

                    <Row>
                      <button
                        name="submit"
                        type="submit"
                        className="Button"
                        disabled={serverState.submitting}
                      >
                        Get in touch
                      </button>
                    </Row>

                    {serverState.status && (
                      <p className={!serverState.status.ok ? "errorMsg" : ""}>
                        {serverState.status.msg}
                      </p>
                    )}
                  </form>
                </Form>
              </>
            )}

            <div className="container-recaptcha"></div>
          </TopHeading>
        </Container>
      </FormArea>
    </>
  );
};

export default RegistrationForm;
