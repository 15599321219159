import Box from "../../../common/components/Box";
import Text from "../../../common/components/Text";
import Container from "../../../common/components/UI/Container";
import { FOOTER_DATA } from "../../../common/data/SecD3v";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Modal } from "react-responsive-modal";
// import About from "../../../containers/SecD3v/About";
import ReturnPolicy from "../../../containers/SecD3v/ReturnPolicy";
import React, { useState } from "react";
import FooterArea from "./footer.style";

const Footer = () => {
  const { logo, menu, social } = FOOTER_DATA;
  const [openAbout, setOpenAbout] = useState(false);
  const [openReturn, setOpenReturnPolicy] = useState(false);

  const onOpenModalAbout = () => setOpenAbout(true);
  const onCloseModalAbout = () => setOpenAbout(false);

  const onOpenModalReturnPolicy = () => setOpenReturnPolicy(true);
  const onCloseModalReturnPolicy = () => setOpenReturnPolicy(false);

  return (
    <FooterArea>
      <Container>
        <Box className="logoBox">
          <a href="/" className="logo">
            <img src={logo} className="logoFooter" alt="SecD3v logo" />
          </a>
        </Box>
      </Container>
      <Container>
        <Box className="menu">
          <a
            onClick={onOpenModalReturnPolicy}
            className="menuLinkAbout"
            target="_blank"
          >
            Cancellation Policy
          </a>
          {menu.map(({ link, label }, index) => (
            <a
              href={link}
              key={`footer-menu-link-${index}`}
              className="menuLink"
              target={link === "/contact" || "/about" ? "_self" : "_blank"}
            >
              {label}
            </a>
          ))}
        </Box>
        <Box className="social">
          {/* <Text as="span" content="Social:" /> */}
          {social.map(({ icon, link }, index) => (
            <a
              href={link}
              key={`footer-social-link-${index}`}
              className="socialLink"
              target="_blank"
            >
              <img className="footerSocial" src={icon} alt="social icon" />
            </a>
          ))}
        </Box>
      </Container>
      <Container className="footerLine">
        <Box className="logoBox">
          <Text as="p" content={`Copyright © ${new Date().getFullYear()}`} />
        </Box>
      </Container>
      {/* <Modal open={openAbout} onClose={onCloseModalAbout} center>
        <About />
      </Modal> */}
      <Modal open={openReturn} onClose={onCloseModalReturnPolicy} center>
        <ReturnPolicy />
      </Modal>
    </FooterArea>
  );
};

export default Footer;
