import themeGet from "@styled-system/theme-get";
import styled from "styled-components";

const FormArea = styled.section`
  height: 45vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .Button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    margin-bottom: 50px;
    margin-top: 30px;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    line-height: 1;
    color: #ffffff;
    padding: 21px 29px;
    background-color: #132636;
    cursor: pointer;
    border: 0;
    transition: all 500ms ease;
    &:hover {
      background-color: grey;
      color: white;
      i {
        transform: translateX(2px);
      }
    }
    i {
      margin-left: 10px;
      position: relative;
      top: 1px;
      transition: transform 0.4s ease;
    }
    @media (max-width: 1600px) {
      font-size: 14px;
      padding: 16px 23px;
      margin-bottom: 0;
    }
    @media (max-width: 575px) {
      width: 100%;
    }
  }
  .labelForm {
    display: inline-block;
    width: 150px;
    text-align: center;
  }
  .form-group {
    margin-bottom: 10px;
  }
  .form-control {
    border: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #f2f2f2;
    padding: 12px;
    border-radius: 3px;
    width: 250px;
    font-size: 14px;
  }
  .aboutUsText {
    margin-right: 30px;
    margin-left: 30px;
  }
  .aboutUsContact {
    margin-right: 30px;
    margin-left: 30px;
  }
  .aboutSubheading {
    margin-top: 30px;
  }
`;

export const Container = styled.div`
  background-color: #fff;
  border-radius: 10px;
  // box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  width: 650px;
  max-width: 100%;
  min-height: 350px;
`;

export const TopHeading = styled.div`
  text-align: center;
  h2 {
    font-weight: 600;
    font-size: 28px;
    text-align: center;
    letter-spacing: -0.5px;
    margin-bottom: 0;
    color: #132636;
    @media (max-width: 1600px) {
      font-size: 24px;
    }
    @media (max-width: 575px) {
      font-size: 22px;
    }
  }
`;

export default FormArea;
