import React, { useState } from "react";
import Heading from "../../../common/components/Heading";

import FormArea, {
  Container,
  CardFooter,
  CardTop,
  Col,
  PriceCard,
  PricingAmount,
  Row,
  TopHeading,
} from "./returnPolicy.style";

const CancellationPolicy = () => {
  return (
    <>
      <FormArea>
        <Container>
          <TopHeading>
            <Heading as="h2" content="Cancellation Policy" />
            <Heading as="h4" content="SecD3v is a subsidiary of x-RD " />
          </TopHeading>
          <p className="aboutUsText">
            In the event that the Customer terminates the SecD3v service with 14
            day's written notice, x-RD will refund to the Customer a pro-rated
            portion of pre-paid amounts for services not actually received by
            the Customer as of the date of such termination.
          </p>
          <TopHeading>
            <Heading
              className="aboutSubheading"
              as="h4"
              content="For more information"
            />
          </TopHeading>

          <ul className="aboutUsContact">
            <li>
              Phone: <strong>(02) 6109 7672</strong>
            </li>
            <li>
              Email: <strong>enquiries@secd3v.com.au</strong>
            </li>
            <li>
              Address:
              <strong>
                {" "}
                Suite 2.02, 15 London Circuit, Canberra ACT 2601, Australia
              </strong>
            </li>
          </ul>
        </Container>
      </FormArea>
    </>
  );
};

export default CancellationPolicy;
