import React from "react";
import { Link } from "gatsby";

const Dropdown = ({ submenus, dropdown }) => {
  return (
    <>
      <ul className={`dropdown ${dropdown ? "dropdown-show" : ""}`}>
        {submenus?.map((submenu, index) =>
          submenu.url.length > 0 ? (
            <li key={index} className="drop-menu-items">
              <Link className="drop-menu-item" to={submenu.url}>
                {submenu.title}
              </Link>
            </li>
          ) : (
            <></>
          )
        )}
      </ul>
    </>
  );
};

export default Dropdown;
